import React, { useState, useEffect } from 'react';
import { AppBar, Toolbar, IconButton, Container, Box, Drawer, List, ListItem, ListItemText, Collapse, Divider, Typography } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import CloseIcon from '@mui/icons-material/Close';
import { styled, useTheme } from '@mui/material/styles';
import logo from './images/logo.jpg'; // Ensure this path is correct
import { Link } from 'react-router-dom';

const LogoContainer = styled(Box)(({ theme }) => ({
  padding: '15px', 
  display: 'flex',
  alignItems: 'center',
  cursor: 'pointer',
}));

const LogoImage = styled('img')(({ theme }) => ({
  height: '80px',
  borderRadius: '18px',
  marginRight: theme.spacing(2),
}));

const HamburgerButton = styled(IconButton)(({ theme }) => ({
  position: 'absolute',
  right: '16px', // Golden ratio (8px * 2)
}));

const CloseButton = styled(IconButton)(({ theme }) => ({
  position: 'absolute',
  right: '16px',
  top: '12px',
  color: 'black',
}));

const DrawerContainer = styled(Box)(({ theme }) => ({
  width: 250,
  backgroundColor: '#F0F8FF', // Light blue background
  height: '100%',
  paddingTop: theme.spacing(4),
  paddingLeft: theme.spacing(2),
  paddingRight: theme.spacing(2),
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
}));

const ListItemStyled = styled(ListItem)(({ theme }) => ({
  '&:hover': {
    backgroundColor: '#e0f7fa', // Light green on hover
  },
  '& .MuiListItemText-root': {
    color: '#00796b', // Dark green text
  },
}));

const Navbar = () => {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [booksOpen, setBooksOpen] = useState(false);
  const [scrollPosition, setScrollPosition] = useState(0);
  const [showNavbar, setShowNavbar] = useState(true);
  const theme = useTheme(); // Use MUI theme

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollPosition = window.pageYOffset;
      setShowNavbar(scrollPosition > currentScrollPosition || currentScrollPosition < 10);
      setScrollPosition(currentScrollPosition);
    };

    window.addEventListener('scroll', handleScroll, { passive: true });

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [scrollPosition]);

  const toggleDrawer = (open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setDrawerOpen(open);
  };

  const handleBooksClick = (event) => {
    event.stopPropagation(); // Prevent the drawer from closing
    setBooksOpen(!booksOpen);
  };

  const currentYear = new Date().getFullYear();

  return (
    <AppBar
      position="fixed"
      sx={{
        top: showNavbar ? '0' : '-120px',
        bgcolor: scrollPosition > 100 ? 'black' : 'transparent',
        boxShadow: 'none',
        transition: 'top 0.3s ease, background-color 0.3s ease',
      }}
    >
      <Container maxWidth="lg">
        <Toolbar>
          <LogoContainer className="no-dec" component={Link} to="/">
            <LogoImage src={logo} alt="Logo" />
          </LogoContainer>
          <HamburgerButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            onClick={toggleDrawer(true)}
          >
            <MenuIcon />
          </HamburgerButton>
          <Drawer anchor="right" open={drawerOpen} onClose={toggleDrawer(false)}>
            <DrawerContainer role="presentation">
              <Box>
                <CloseButton onClick={toggleDrawer(false)}>
                  <CloseIcon />
                </CloseButton>
                <List>
                  <ListItemStyled button component={Link} to="/">
                    <ListItemText primary="Home" />
                  </ListItemStyled>
                  <ListItemStyled button component={Link} to="/contact-page">
                    <ListItemText primary="Contact" />
                  </ListItemStyled>
                  <ListItemStyled button component={Link} to="/blogs">
                    <ListItemText primary="Blogs" />
                  </ListItemStyled>
                  <ListItemStyled button onClick={handleBooksClick}>
                    <ListItemText primary="Books" />
                    {booksOpen ? <ExpandLess /> : <ExpandMore />}
                  </ListItemStyled>
                  <Collapse in={booksOpen} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                      <ListItemStyled button sx={{ pl: 4 }} component={Link} to="/stumbling-in-the-dark-looking-for-the-light-switch">
                        <ListItemText primary="Stumbling in the Dark" />
                      </ListItemStyled>
                    </List>
                  </Collapse>
                </List>
              </Box>
              <Divider />
              <Box sx={{ textAlign: 'center', padding: theme.spacing(2) }}>
                <Typography variant="body2" color="textSecondary">
                  &copy; {currentYear} Family Reunite Network
                </Typography>
              </Box>
            </DrawerContainer>
          </Drawer>
        </Toolbar>
      </Container>
    </AppBar>
  );
};

export default Navbar;

// src/pages/BlogList.js

import React, { useState, useEffect } from 'react';
import {
  Typography,
  Grid,
  Card,
  CardContent,
  CardActions,
  Button,
  CardMedia,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { Link as RouterLink } from 'react-router-dom';
import BookIcon from '@mui/icons-material/Book';

// 1) Import ParallaxProvider & Parallax from react-scroll-parallax
import { ParallaxProvider, Parallax } from 'react-scroll-parallax';

// 2) Framer Motion
import { motion, AnimatePresence } from 'framer-motion';
import Footer from './Footer';
import Navbar from './Navbar';
import BlogBanner from './BlogBanner';

// ====== Styled Container Wrapper (top/bottom spacing) ======
const BlogListContainer = styled('div')(({ theme }) => ({
  paddingTop: '150px',
  paddingBottom: theme.spacing(6),
}));

// ====== Default Blog Logo (fallback if no image in blog data) ======
const defaultLogo = '/logo.jpg';

// ====== Variants for staggered card animations ======
const containerVariants = {
  hidden: { opacity: 0, y: 15 },
  show: {
    opacity: 1,
    y: 0,
    transition: {
      staggerChildren: 0.1,
    },
  },
};
const itemVariants = {
  hidden: { opacity: 0, y: 10 },
  show: { opacity: 1, y: 0 },
};

export function BlogList() {
  const [blogs, setBlogs] = useState([]);

  useEffect(() => {
    fetch('/data/blogs.json')
      .then((res) => res.json())
      .then((data) => setBlogs(data))
      .catch((err) => console.error('Error fetching blogs:', err));
  }, []);

  // Loading / Empty State
  if (!blogs.length) {
    return (
      <BlogListContainer>
        <Typography variant="h6" align="center">
          Loading blogs...
        </Typography>
      </BlogListContainer>
    );
  }

  return (
    <>
    <Navbar />
    <ParallaxProvider>
     
      <BlogListContainer>
      <BlogBanner />
       

        {/* AnimatePresence + motion.div for mount/unmount animations */}
        <AnimatePresence>
          <motion.div
            variants={containerVariants}
            initial="hidden"
            animate="show"
            exit="hidden"
          >
            <Grid
              container
              spacing={4}
              justifyContent="center"
              alignItems="stretch"
              sx={{ px: 2 }}
            >
              {blogs.map((blog) => (
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={4}
                  key={blog.id}
                  sx={{ display: 'flex' }}
                >
                  {/* Wrap each card in a Parallax for subtle scrolling effect */}
                  <Parallax
                    translateY={[-5, 5]} // or any small range for slight parallax
                    style={{ width: '100%', display: 'flex' }}
                  >
                    {/* Each Grid item is also a motion.div for fade-in/up */}
                    <motion.div
                      variants={itemVariants}
                      style={{ flex: 1, display: 'flex' }}
                    >
                      <Card
                        sx={{
                          display: 'flex',
                          flexDirection: 'column',
                          flex: 1,
                          boxShadow: 3,
                          textAlign: 'center',
                          transition: 'transform 0.2s ease, box-shadow 0.2s ease',
                          '&:hover': {
                            transform: 'scale(1.02)',
                            boxShadow: 6,
                          },
                        }}
                      >
                        {/* 
                          Conditionally render CardMedia:
                          - If blog has an imageUrl, just show it normally.
                          - If blog has NO imageUrl, animate the default logo infinitely.
                        */}
                        {blog.imageUrl ? (
                          <CardMedia
                            component="img"
                            sx={{
                              height: 220,
                              objectFit: 'contain',
                            }}
                            image={blog.imageUrl}
                            alt="Blog Image"
                          />
                        ) : (
                          <CardMedia
                            component={motion.img}
                            sx={{
                              height: 220,
                              objectFit: 'cover',
                            }}
                            src={defaultLogo}
                            alt="Default Logo"
                            animate={{ scale: [1, 0.9, 1] }}
                            transition={{
                              duration: 1.5,
                              repeat: Infinity,
                              repeatType: 'reverse',
                            }}
                          />
                        )}

                        <CardContent
                          sx={{
                            flex: 1,
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center',
                          }}
                        >
                          <Typography
                            variant="h6"
                            gutterBottom
                            sx={{
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'center',
                              gap: 1,
                              fontWeight: 'bold',
                              fontSize: '1rem',
                            }}
                          >
                            <BookIcon color="primary" />
                            {blog.title}
                          </Typography>
                          <Typography variant="body2" color="text.secondary">
                            {blog.content.slice(0, 70)}...
                          </Typography>
                        </CardContent>

                        <CardActions
                          sx={{
                            justifyContent: 'center',
                            pb: 2,
                          }}
                        >
                          <Button
                            variant="contained"
                            component={RouterLink}
                            to={`/blogs/${blog.id}`}
                            sx={{ textTransform: 'none' }}
                          >
                            Read More
                          </Button>
                        </CardActions>
                      </Card>
                    </motion.div>
                  </Parallax>
                </Grid>
              ))}
            </Grid>
          </motion.div>
        </AnimatePresence>
      </BlogListContainer>
    </ParallaxProvider>
    <Footer />
    </>
  );
}

// src/pages/SingleBlogPage.js

import React, { useState, useEffect } from 'react';
import {
  Container,
  Typography,
  Paper,
  Button,
  Chip,
  Box,
  Avatar,
  IconButton,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { useParams, Link as RouterLink } from 'react-router-dom';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import logo from '../pages/images/logo.jpg'; // Adjust path as needed
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { motion } from 'framer-motion';
import Navbar from './Navbar';
import Footer from './Footer';

// ============== STYLES ==============
const BlogContainer = styled(Container)(({ theme }) => ({
  paddingTop: '150px',
}));

const BlogPaper = styled(Paper)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: theme.spacing(3),
  backgroundColor: '#fff8e1',
  boxShadow: '0 4px 20px rgba(0, 0, 0, 0.1)',
  borderRadius: '8px',
  transition: 'transform 0.3s ease, box-shadow 0.3s ease',
  '&:hover': {
    transform: 'scale(1.05)',
    boxShadow: '0 8px 30px rgba(0, 0, 0, 0.2)',
  },
}));

const BlogTitle = styled(Typography)(({ theme }) => ({
  margin: theme.spacing(2, 0),
  fontWeight: 'bold',
  color: '#3e2723',
  textShadow: '1px 1px 2px rgba(0, 0, 0, 0.3)',
}));

const BlogContent = styled(Typography)(({ theme }) => ({
  color: '#5d4037',
  marginBottom: theme.spacing(2),
}));

const BlogButton = styled(Button)(({ theme }) => ({
  backgroundColor: '#ffab40',
  color: '#3e2723',
  '&:hover': {
    backgroundColor: '#ff9100',
  },
}));

const BlogChip = styled(Chip)(({ theme }) => ({
  marginBottom: theme.spacing(2),
  borderColor: '#3e2723',
  color: '#3e2723',
}));

const BlogAvatar = styled(Avatar)(({ theme }) => ({
  width: theme.spacing(12),
  height: theme.spacing(12),
  marginBottom: theme.spacing(2),
  border: '2px solid #ffab40',
  '&:hover': {
    borderColor: '#ff9100',
  },
}));

const NavigationBar = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  color: 'white',
  marginBottom: theme.spacing(3),
}));

export function SingleBlogPage() {
  const { id } = useParams(); // from /blogs/:id
  const [blogs, setBlogs] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    fetch('/data/blogs.json')
      .then((response) => response.json())
      .then((data) => {
        if (!data || data.length === 0) return;
        setBlogs(data);

        if (id) {
          // If we have an ID param, see if that blog exists
          const foundIndex = data.findIndex((b) => b.id === Number(id));
          if (foundIndex >= 0) {
            setCurrentIndex(foundIndex);
          } else {
            setIndexToNewest(data);
          }
        } else {
          // If no ID param, just go to newest blog
          setIndexToNewest(data);
        }
      })
      .catch((error) => console.error('Error fetching the blogs:', error));
  }, [id]);

  const setIndexToNewest = (data) => {
    // find the blog with the highest ID (i.e. the "newest")
    const maxId = Math.max(...data.map((b) => b.id));
    const indexOfMaxId = data.findIndex((b) => b.id === maxId);
    setCurrentIndex(indexOfMaxId);
  };

  const handleNext = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % blogs.length);
  };

  const handlePrevious = () => {
    setCurrentIndex((prevIndex) => (prevIndex - 1 + blogs.length) % blogs.length);
  };

  if (blogs.length === 0) {
    return (
      <Container sx={{ paddingTop: '150px' }}>
        <Typography variant="h5">Loading...</Typography>
      </Container>
    );
  }

  const currentBlog = blogs[currentIndex];

  return (
    <HelmetProvider>
      <Navbar />
      <BlogContainer>
        <Helmet>
          <title>{currentBlog.title} | Family Reunite Network</title>
          <meta
            name="description"
            content={currentBlog.content.slice(0, 150) + '...'}
          />
        </Helmet>

        <Typography color="white" variant="h3" gutterBottom>
          Blog Posts
        </Typography>

        {/* Navigation Bar with Prev/Next Icons */}
        <NavigationBar>
          <IconButton onClick={handlePrevious} disabled={blogs.length <= 1}>
            <ArrowBackIcon style={{ color: 'white' }} />
          </IconButton>
          <Typography variant="body1" color="white">
            {currentIndex + 1} / {blogs.length}
          </Typography>
          <IconButton onClick={handleNext} disabled={blogs.length <= 1}>
            <ArrowForwardIcon style={{ color: 'white' }} />
          </IconButton>
        </NavigationBar>

        {/* Motion wrapper for the Single Blog Card */}
        <motion.div
          initial={{ opacity: 0, y: 50 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: -50 }}
          transition={{ duration: 0.5 }}
        >
          <BlogPaper elevation={3}>
            {/* Logo Avatar link back to "/" */}
            <Box display="flex" justifyContent="center">
              <RouterLink to="/">
                <BlogAvatar src={logo} alt="Logo" />
              </RouterLink>
            </Box>

            <BlogTitle variant="h5" gutterBottom>
              {currentBlog.title}
            </BlogTitle>

            {/* Convert `\n` in the content to line-breaks */}
            <BlogContent variant="body1">
              {currentBlog.content.split('\n').map((paragraph, idx) => (
                <React.Fragment key={idx}>
                  {paragraph}
                  <br />
                </React.Fragment>
              ))}
            </BlogContent>

            {/* Example: Hard-coded Author or dynamic, as you like */}
            <BlogChip label="Author - T.J. Scott" variant="outlined" />

            {/* Optional: External Link Button if present */}
            {currentBlog.externalLink && (
              <a
                href={currentBlog.externalLink}
                target="_blank"
                rel="noopener noreferrer"
              >
                <BlogButton variant="contained">Learn More!</BlogButton>
              </a>
            )}
          </BlogPaper>
        </motion.div>
      </BlogContainer>
      <Footer />
    </HelmetProvider>
  );
}

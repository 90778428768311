import React from 'react';
import {  Typography, Paper } from '@mui/material';
import { styled } from '@mui/material/styles';

const BannerWrapper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(3),
  marginTop: theme.spacing(4),
  textAlign: 'center',
  background: 'transparent',
  color: '#fff',
  marginBottom: 20,
}));

export default function BlogBanner() {
  return (
    <BannerWrapper elevation={4}>
      <Typography variant="h4" sx={{ fontWeight: 'bold', mb: 1 }}>
        Welcome to T.J. Scott&apos;s Blog Page
      </Typography>
      <Typography variant="body1">
        This is where you&apos;ll find original pieces written by T.J. Scott. Enjoy reading!
      </Typography>
    </BannerWrapper>
  );
}

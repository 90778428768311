import React, { Suspense, lazy, useEffect, useState } from 'react';
import { Route, Routes } from 'react-router-dom';
import { BrowserRouter } from 'react-router-dom';
import './App.css';
import Loading from './Loading';
import ScrollToTop from './ScrollToTop';

// MUI imports:
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { Link as RouterLink } from 'react-router-dom';
import blogPlaceholder from './assets/Family-Reunite-Network.jpg';
import { SingleBlogPage } from './components/SingleBlogPage';
import { BlogList } from './components/BlogList';
// Lazy-loaded pages:
const Home = lazy(() => import('./pages/Home'));
const ContactPage = lazy(() => import('./pages/ContactPage'));
const StumblingInTheDark = lazy(() => import('./pages/StumblingInTheDark'));



const PopupImage = styled('img')(({ theme }) => ({
  width: 'auto',
  height: '200px',
  borderRadius: '8px',
  margin: '0 auto',
  marginBottom: theme.spacing(2),
}));

function App() {
  const [openModal, setOpenModal] = useState(true);
  const [newestBlog, setNewestBlog] = useState(null);

  // Fetch the blogs and find the one with the highest ID.
  useEffect(() => {
    fetch('/data/blogs.json')
      .then((res) => res.json())
      .then((blogs) => {
        if (blogs && blogs.length > 0) {
          // Identify blog with max ID
          const highestIdBlog = blogs.reduce((maxBlog, current) => {
            return current.id > maxBlog.id ? current : maxBlog;
          }, blogs[0]);
          setNewestBlog(highestIdBlog);
        }
      })
      .catch((error) => {
        console.error('Failed to fetch blogs:', error);
      });
  }, []);

  const handleClose = () => {
    setOpenModal(false);
  };

  return (
    <div className="App">
      <BrowserRouter>
        <Suspense fallback={<Loading />}>
          <ScrollToTop />

          {/* POPUP DIALOG for newest blog */}
          {newestBlog && (
            <Dialog
              open={openModal}
              onClose={handleClose}
              aria-labelledby="newest-blog-dialog"
              
            >
              <DialogTitle id="newest-blog-dialog" sx={{textAlign: 'center'}}>
                Read T.J. Scott&apos;s Newest Blog
              </DialogTitle>
              <DialogContent sx={{display: 'flex' , flexDirection: 'column' , justifyContent: 'center', alignItems: 'center'}}>
                {/* Example image/thumbnail; replace or remove as desired. */}
                <PopupImage src={blogPlaceholder} alt="Newest Blog" />

                <Typography variant="h6" gutterBottom>
                  {newestBlog.title}
                </Typography>

                {/* Optional: short snippet from the blog content */}
                <Typography variant="body2" color="text.secondary">
                  {newestBlog.content.slice(0, 150)}...
                </Typography>
              </DialogContent>

              <DialogActions>
                <Button onClick={handleClose} color="inherit">
                  Close
                </Button>
                <Button
  variant="contained"
  component={RouterLink}
  to={`/blogs/${newestBlog.id}`}  // <-- Use newestBlog.id
  onClick={handleClose}
>
  View Blog
</Button>

              </DialogActions>
            </Dialog>
          )}

          {/* Main Routes */}
          <Routes>
            <Route path="/*" element={<Home />} />
            <Route path="/" element={<Home />} />
            <Route path="/contact-page" element={<ContactPage />} />
            <Route
              path="/stumbling-in-the-dark-looking-for-the-light-switch"
              element={<StumblingInTheDark />}
            />
            {/* 1) List of all blogs */}
            <Route path="/blogs" element={<BlogList />} />

            {/* 2) Single blog page by ID */}
            <Route path="/blogs/:id" element={<SingleBlogPage />} />
          </Routes>
        </Suspense>
      </BrowserRouter>
    </div>
  );
}

export default App;
